<template>
  <div class="order-detail position-fixed d-flex justify-content-end">
    <transition name="fade" mode="out-in">
      <div
        class="bg-overlay position-absolute"
        @click="() => closeOrder()"
        v-if="lazyVisible"
      ></div>
    </transition>
    <transition name="order-slide-right-edit" mode="out-in" key="order-details-slide-right">
      <div v-if="orderDrawnerleft" class="order-drawner-left edit">
        <header class="bg-primary position-relative d-flex align-items-center justify-content-end">
          <p class="h6 text-white m-0 pr-3 font-weight-bold">
            {{ $t('orderEditModals.informations') }}
          </p>
          <close-button class="close-button position-absolute" :handleClick="toggleEditModal" />
        </header>
        <div class="bg-white content position-relative">
          <loading-overlay v-if="loading" class="loading-fade" noRounded></loading-overlay>
          <b-tabs fill active-nav-item-class="tab-active" no-nav-style>
            <b-tab
              :title="$t('orderEditModals.tabPersonalData')"
              title-link-class="text-dark-darken font-weight-bold small-text"
            >
              <form-creator
                type="customer"
                :data="form.customer"
                :editable="operation !== 'spot'"
                onChange
                allData
                @change="data => setFormValue(data, 'customer')"
              />
            </b-tab>
            <b-tab
              :title="$t('orderEditModals.tabAddressData')"
              title-link-class="text-dark-darken font-weight-bold small-text"
            >
              <b-tabs
                v-if="operation === 'spot'"
                class="border-top"
                fill
                active-nav-item-class="tab-active"
                no-nav-style
              >
                <b-tab
                  title="Endereço de Coleta"
                  title-link-class="text-dark-darken font-weight-bold small-text"
                >
                  <form-creator
                    type="address"
                    :data="sourceAddress"
                    onChange
                    allData
                    @change="data => setFormValue(data, 'source')"
                  />
                </b-tab>
                <b-tab
                  title="Endereço de Entrega"
                  title-link-class="text-dark-darken font-weight-bold small-text"
                >
                  <form-creator
                    type="address"
                    :data="address"
                    onChange
                    allData
                    @change="data => setFormValue(data, 'destination')"
                  />
                </b-tab>
              </b-tabs>
              <form-creator
                v-else
                editable
                type="address"
                :data="address"
                onChange
                allData
                @change="data => setFormValue(data, 'address')"
              />
            </b-tab>
            <b-tab
              :title="$t('orderEditModals.tabInvoice')"
              title-link-class="text-dark-darken font-weight-bold small-text"
            >
              <div v-for="(invoice, index) in invoices" :key="invoice.number">
                <form-creator
                  type="nf-e"
                  :data="invoice"
                  :editable="operation !== 'spot'"
                  onChange
                  allData
                  @change="data => setFormValue(data, 'nf-e', index)"
                />
              </div>
            </b-tab>
            <b-tab
              :title="$t('orderEditModals.tabVolumesData')"
              title-link-class="text-dark-darken font-weight-bold small-text"
            >
              <perfect-scrollbar class="scroll-container-volumes">
                <div v-for="(volume, index) in volumes" :key="`volume-${index}`">
                  <p class="font-weight-bold text-dark-darken px-3 pt-2 mb-0">
                    Volume #{{ index + 1 }}
                  </p>
                  <form-creator
                    type="volume"
                    :data="volume"
                    component="div"
                    :editable="operation !== 'spot'"
                    onChange
                    allData
                    @change="data => setFormValue(data, 'volume', index)"
                  />
                </div>
              </perfect-scrollbar>
            </b-tab>
          </b-tabs>

          <div class="px-3 d-flex mt-3">
            <uello-button
              class="flex-1"
              color="red"
              @click="saveOrder"
              :disabled="operation === 'spot'"
              :text="$t('form.buttons.sendChangeOrder')"
            />
          </div>
        </div>
      </div>
    </transition>
    <transition name="order-slide-right" mode="out-in">
      <div v-if="orderDrawnerRight" class="order-drawner-right order shadow">
        <header class="bg-primary position-relative d-flex align-items-center">
          <p class="h5 text-white m-0 pl-3 font-weight-bold">{{ info.customer.name | maxWords }}</p>
          <close-button class="close-button position-absolute" :handleClick="closeOrder" />
        </header>
        <div class="bg-white content">
          <div class="p-3 border-bottom">
            <div
              class="bg-warning rounded p-2 ocurrence mb-3"
              v-if="!order.ocurrence && Number(order.tries) > 0"
            >
              <p class="text-dark m-0">
                <small>
                  <b>{{ getOcurrenceMessage }}</b>
                </small>
              </p>
            </div>
            <div class="bg-warning rounded p-2 ocurrence mb-3" v-if="order.ocurrence">
              <p class="text-dark font-weight-bold">
                <small>
                  <b>{{ order.ocurrence.status }}</b>
                </small>
              </p>
              <p class="text-dark font-weight-bold">
                <small>
                  <b>{{ getOcurrenceMessage }}</b>
                </small>
              </p>
              <p class="text-dark m-0">
                <small>{{ order.ocurrence.message }}</small>
              </p>
            </div>
            <uello-button
              @click="toggleEditModal"
              :disabled="orderDrawnerleft"
              icon="eye"
              :text="$t('general.userInformationEditButton') | capitalize"
            />
          </div>
          <perfect-scrollbar class="scroll-container">
            <div role="tablist">
              <button
                class="options-button w-100 pl-3 pr-1 py-2 d-flex justify-content-between align-items-center"
                :class="{
                  'active mb-3': isActive('accordion-info'),
                }"
                @click="toggleVisible('accordion-info')"
              >
                <span :class="isActive('accordion-info') ? 'text-primary' : 'text-dark-darken'"
                  ><b>{{ $t('orderEditModals.informations') }}</b></span
                >
                <icon
                  icon="chevron-right"
                  :color="`var(--${isActive('accordion-info') ? 'primary' : 'dark-darken'})`"
                  :size="30"
                />
              </button>
              <div class="px-3">
                <b-collapse
                  id="accordion-info"
                  :visible="visible === 'accordion-info'"
                  accordion="order-desc"
                  role="tabpanel"
                >
                  <accordion-info :data="info" />
                </b-collapse>
              </div>
              <hr class="m-0" />
              <button
                class="options-button w-100 pl-3 pr-1 py-2 d-flex justify-content-between align-items-center"
                :class="{
                  'active mb-3': isActive('accordion-details'),
                }"
                @click="toggleVisible('accordion-details')"
              >
                <span :class="isActive('accordion-details') ? 'text-primary' : 'text-dark-darken'"
                  ><b>{{ $t(`orderEditModals.${operation}`) }}</b></span
                >
                <icon
                  icon="chevron-right"
                  :color="`var(--${isActive('accordion-details') ? 'primary' : 'dark-darken'})`"
                  :size="30"
                />
              </button>
              <div class="px-3">
                <b-collapse
                  id="accordion-details"
                  :visible="visible === 'accordion-details'"
                  accordion="order-desc"
                  role="tabpanel"
                >
                  <accordion-details :data="details" />
                </b-collapse>
              </div>
              <hr class="m-0" />
              <button
                class="options-button w-100 pl-3 pr-1 py-2 d-flex justify-content-between align-items-center"
                :class="{
                  'active mb-3': isActive('accordion-volumes'),
                }"
                @click="toggleVisible('accordion-volumes')"
              >
                <span :class="isActive('accordion-volumes') ? 'text-primary' : 'text-dark-darken'"
                  ><b>{{ $t('orderEditModals.infoDetails.volumesQtd') | capitalize }}</b></span
                >
                <icon
                  icon="chevron-right"
                  :color="`var(--${isActive('accordion-volumes') ? 'primary' : 'dark-darken'})`"
                  :size="30"
                />
              </button>
              <div class="px-3">
                <b-collapse
                  id="accordion-volumes"
                  :visible="visible === 'accordion-volumes'"
                  accordion="order-desc"
                  role="tabpanel"
                >
                  <accordion-volumes :data="order.items" />
                </b-collapse>
              </div>
              <hr class="m-0" />
              <button
                v-if="actionsVisible"
                class="options-button w-100 pl-3 pr-1 py-2 d-flex justify-content-between align-items-center"
                :class="{
                  'active mb-3': isActive('accordion-actions'),
                }"
                @click="toggleVisible('accordion-actions')"
              >
                <span :class="isActive('accordion-actions') ? 'text-primary' : 'text-dark-darken'"
                  ><b>{{ $t('orderEditModals.actions') }}</b></span
                >
                <icon
                  icon="chevron-right"
                  :color="`var(--${isActive('accordion-actions') ? 'primary' : 'dark-darken'})`"
                  :size="30"
                />
              </button>
              <div class="px-3" v-if="actionsVisible">
                <b-collapse
                  id="accordion-actions"
                  :visible="visible === 'accordion-actions'"
                  accordion="order-desc"
                  role="tabpanel"
                >
                  <accordion-actions :data="actions" @cancel-order="cancelOrder" :order="order" />
                </b-collapse>
              </div>
              <hr class="m-0" />
              <button
                class="options-button w-100 pl-3 pr-1 py-2 d-flex justify-content-between align-items-center"
                :class="{
                  'active mb-3': isActive('accordion-history'),
                }"
                @click="toggleVisible('accordion-history')"
              >
                <span :class="isActive('accordion-history') ? 'text-primary' : 'text-dark-darken'"
                  ><b>{{ $t('orderEditModals.history') }}</b></span
                >
                <icon
                  icon="chevron-right"
                  :color="`var(--${isActive('accordion-history') ? 'primary' : 'dark-darken'})`"
                  :size="30"
                />
              </button>
              <div class="px-3">
                <b-collapse
                  id="accordion-history"
                  :visible="visible === 'accordion-history'"
                  accordion="order-desc"
                  role="tabpanel"
                >
                  <accordion-history :data="history" />
                </b-collapse>
              </div>
              <hr class="m-0" />
            </div>
            <div class="p-3">
              <uello-button
                class="open-ticket w-100 text-left"
                color="red"
                id="open-ticket"
                icon="ticket-confirmation-outline"
                @click="openNewMovideskTicket"
                :text="$t('orderEditModals.openNewTicket', { ticketOrderId })"
              />
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import api from '@/services/api';
import { widthMixin } from '@/mixins';
import { UelloButton } from '@uello/componentello';

export default {
  name: 'order-detail',
  mixins: [widthMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    AccordionInfo: () => import('./AccordionInfo.vue'),
    AccordionDetails: () => import('./AccordionDetails.vue'),
    AccordionActions: () => import('./AccordionActions.vue'),
    AccordionVolumes: () => import('./AccordionVolumes.vue'),
    AccordionHistory: () => import('./AccordionHistory.vue'),
    LoadingOverlay: () => import('./FollowAgGridLoadingOverlay.vue'),
    UelloButton,
  },
  data() {
    return {
      visible: null,
      orderDrawnerRight: false,
      orderDrawnerleft: false,
      lazyVisible: false,
      form: null,
      loading: false,
    };
  },
  beforeMount() {
    this.form = this.order;
  },
  mounted() {
    setTimeout(() => {
      this.orderDrawnerRight = true;
    }, 200);
    window.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.closeOrder();
      }
    });

    this.toggleLateralEdit(true);
  },
  destroyed() {
    window.removeEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.closeOrder();
      }
    });
  },
  computed: {
    ticketOrderId() {
      return `: ${this.order.invoices[0].number}`;
    },
    actionsVisible() {
      const { returnable, retryable, deletable, recoverable, confrontation } = this.order;

      return returnable || retryable || deletable || recoverable || confrontation;
    },
    getOcurrenceMessage() {
      const message =
        this.$t('messages.orderOcurrenceTries', {
          count: this.order.tries,
          operation: this.$t(`operations.${this.order.operation.type}`),
        }) || '';

      return message;
    },
    operation() {
      return this.order.operation.type;
    },
    address() {
      const { geolocation, ...address } = this.form[
        this.form.operation.type === 'delivery' ? 'destination' : 'source'
      ];

      return { ...address, geolocation };
    },
    sourceAddress() {
      const { geolocation, ...address } = this.form.source;

      return { ...address, geolocation };
    },
    info() {
      if (this.form) {
        /* eslint-disable camelcase */
        const [invoice] = this.form.invoices;
        const {
          state,
          created_at,
          delivered_data,
          operation,
          company,
          source,
          destination,
          tracking,
          items,
          customer,
          shipping_number,
        } = this.form;

        return {
          items,
          invoice,
          state,
          created_at,
          delivered_data,
          operation,
          company,
          source,
          destination,
          tracking,
          customer,
          volumesQtd: this.form.items.length,
          shipping_number,
        };
      }
      return [];
    },
    details() {
      const { driver, delivered_data, operation, source, destination, state } = this.form;
      const data = { driver, delivered_data, operation, source, destination, state };

      const composedData = Object.assign(
        data,
        this.form.pickedup_data ? { pickedup_data: this.form.pickedup_data } : {}
      );

      return composedData;
    },
    invoices() {
      const { invoices, items } = this.form;

      const invoicesMaped = invoices.map(invoice => ({
        ...invoice,
        total: Number(invoice.total).toFixed(2),
        identification: items[0].identification,
      }));

      return invoicesMaped;
    },
    history() {
      const { history } = this.form;

      return history || [];
    },
    actions() {
      const { id, returnable, retryable, deletable, operation, company } = this.form;

      return {
        id,
        returnable,
        retryable,
        deletable,
        operation: operation.type,
        document: company.document,
      };
    },
    volumes() {
      return this.form.items.map(item => ({
        identification: item.identification,
        volume: item.volume,
        weight: item.weight,
      }));
    },
  },
  methods: {
    toggleVisible(id) {
      if (this.visible === id) {
        this.visible = false;
      } else {
        this.visible = id;

        // eslint-disable-next-line no-restricted-syntax
        for (const el of document.querySelectorAll(`#${id} [staticmap]`)) {
          const staticMap = el.getAttribute('staticmap');
          el.removeAttribute('staticmap');
          api.get(staticMap).then(res => {
            el.style.backgroundImage = `url('${res.data}')`;
          });
        }
      }
    },
    toggleEditModal() {
      this.orderDrawnerleft = !this.orderDrawnerleft;
    },
    isActive(id) {
      return this.visible === id;
    },
    closeOrder() {
      this.$emit('closeOrder');
      this.lazyVisible = false;
      this.orderDrawnerleft = false;
      this.orderDrawnerRight = false;
    },
    toggleLateralEdit(visible = false) {
      this.lazyVisible = visible;

      if (!visible) {
        setTimeout(() => {
          this.closeOrder();
        }, 300);
      }
    },
    setFormValue(data, type, index = 0) {
      switch (type) {
        case 'customer': {
          this.form.customer = data;
          break;
        }
        case 'address': {
          this.form[this.form.operation.type === 'pickup' ? 'source' : 'destination'] = data;
          break;
        }
        case 'nf-e': {
          const { key, number, date, serie, total } = data;
          this.form.invoices[index] = { key, number, date, serie, total };
          this.form.items[0].identification = data.identification;
          break;
        }
        case 'volume': {
          this.form.items[index] = { ...this.form.items[index], ...data };
          break;
        }
        default:
          this.form[type] = data;
      }
    },
    async saveOrder() {
      this.loading = true;

      try {
        const { data: response } = await api.put(`/orders/${this.form.id}`, this.form);
        this.$toast.success(this.$t('messages.updateOrderSuccess', { id: response.data.id }));
        this.closeOrder();
      } catch (error) {
        this.$toast.error(this.$t('messages.updateOrderError', { id: this.form.id }));
      }

      this.loading = false;
    },
    cancelOrder(id) {
      this.$emit('cancel-order', id);
    },
    openNewMovideskTicket() {
      this.$emit('open-ticket');
    },
  },
};
</script>

<style lang="scss">
@keyframes loading-fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loading-fade {
  opacity: 0;

  animation: loading-fade 200ms linear forwards;
}

.scroll-container-volumes {
  height: calc(100vh - 200px) !important;
}

.order-detail {
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 999;

  // order-slide-right
  .order-slide-right-edit-enter-active,
  .order-slide-right-edit-leave-active {
    transition: all 300ms ease;
  }

  .order-slide-right-edit-enter,
  .order-slide-right-edit-leave-to {
    transform: translateX(500px);
  }

  .scroll-container,
  .scroll-container-volumes {
    height: calc(100vh - 200px);
  }

  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .nav-link {
    padding: 0.5rem 0.3rem !important;
  }

  ul[role='tablist'].nav.nav-tabs {
    border: 0;

    li[role='presentation'].nav-item {
      a {
        border: 0;
      }
    }
  }

  .active.tab-active {
    border-bottom: 3px solid var(--primary) !important;
    font-weight: bold;
    color: var(--primary) !important;
  }

  .options-button {
    background: none;
    border: 0;
    outline: 0 !important;
    transition: all 200ms linear;

    svg {
      transition: all 250ms linear;
    }

    &.active {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  header {
    height: 50px;
  }

  .content {
    height: calc(100vh - 50px);
  }

  .order-drawner-right {
    width: 400px;
  }

  .order-drawner-left {
    width: 500px;
  }

  .order-drawner-right,
  .order-drawner-left {
    &.order {
      z-index: 18;
    }

    .close-button {
      right: 10px;
      top: calc(25px - 13px);
    }

    &.edit {
      .close-button {
        left: 10px;
        top: calc(25px - 13px);
      }
    }
  }
}
</style>
